import React from "react";
import AppRoutes from "./router/index";

import "@/assets/main.css";
import "@/assets/css/font-awesome.min.css";
import "@/assets/css/bootstrap.min.css";
import "@/assets/css/animate.min.css";
import "@/assets/css/global.css";
import "@/assets/css/lib.css";
import "@/assets/css/style.css";
import "./App.scss";

function App() {
  return <AppRoutes />;
}

export default App;
