import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import store from "@/store/index";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";

const container = document.getElementById("root");
if (container != null) {
  const root = createRoot(container);
  root.render(
    <Provider store={store}>
      <Router>
        <ConfigProvider theme={{ token: { colorPrimary: "#1677ff" } }}>
          <App />
        </ConfigProvider>
      </Router>
    </Provider>
  );
}
